import { Injectable } from '@angular/core';
import { TopbarItem } from '../types/types';

@Injectable()
export class TopbarItemsService{
    private topbarItems:Array<TopbarItem> = [
        {
            label:'Проекты',
            routerLink:'/projects'
        },
        {
            label:'Контакты',
            routerLink:'/contacts'
        },
        {
            label:'Конфигурации',
            routerLink:'/configurations'
        }
    ]

    public getItems(){
        return this.topbarItems;
    }


}