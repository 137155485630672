import { Injectable } from "@angular/core";

@Injectable()
export class MenuService {
    pages = [

        {
            pageName: 'Тип проекта',
            href: 'type-project'
        },

        {
            pageName: 'Отдел ISG',
            href: 'departament'
        },

        {
            pageName: 'Цикличность',
            href: 'cyclical'
        },

        {
            pageName: 'Формат проекта',
            href: 'format-project'
        },

        {
            pageName: 'Тип продаж',
            href: 'type-sale'
        },

        {
            pageName: 'Статусы проектов',
            href: 'status-project'
        },

        {
            pageName: 'Задача проекта',
            href: 'task-project'
        },

        {
            pageName: 'Должности сотрудников',
            href: 'position-employee'
        },

        {
            pageName: 'Сегмент',
            href: 'sphere-activity'
        },

        {
            pageName: 'Тип компании',
            href: 'type-company'
        },
        {
            pageName: 'Страны',
            href: 'country'
        },
        {
            pageName: 'Города',
            href: 'city'
        },
        {
            pageName: 'Зоны проведения',
            href: 'area_conduct'
        },
        {
            pageName: 'Контакты',
            href: 'typeContact'
        }
    ]
    getPages() {
        return this.pages;
    }

    public scroll(id: any): void {
        let element = document.getElementById(id);
        if (element)
            element.scrollIntoView({ behavior: 'smooth', });
    }
}